import axios from "axios";
import React, { useState } from "react";
import { toast } from "react-toastify";

interface ContactFormProps {
  mobile?: boolean;
}

export default function ContactForm({mobile}: ContactFormProps) {
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [telephone, setTelephone] = useState<string>('');
  const [message, setMessage] = useState<string>('');

  const formatTelephone = (value: string) => {
    const numericValue = value.replace(/\D/g, '');
    
    if (numericValue.length <= 2) {
      return numericValue;
    } else if (numericValue.length <= 7) {
      return `(${numericValue.slice(0, 2)}) ${numericValue.slice(2)}`;
    } else {
      return `(${numericValue.slice(0, 2)}) ${numericValue.slice(2, 7)}-${numericValue.slice(7, 11)}`;
    }
  };

  const handleTelephoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const formattedNumber = formatTelephone(e.target.value);
    setTelephone(formattedNumber);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    
    try {
      toast.success('Email enviado com sucesso!');
      await axios.post("https://mailer.abeventos.com/mail/send", {
        subject: "CONTATO ABE-EVENTOS",
        text: message,
        name,
        email,
        telephone
      });
    } catch(e) {
      toast.error('oops! houve um erro ao enviar o email, tente novamente mais tarde.');
    }
    //comercial@abeventos.com
    setName("");
    setEmail("");
    setTelephone("");
    setMessage("");
  }

  return (
    <form 
      className={`flex flex-col gap-y-6 items-center font-black ${mobile ?'mx-6' : 'mx-16'} text-xs justify-center h-full`}
      method="post"
      onSubmit={handleSubmit}
    >
      <input 
        type="text" 
        name="nome" 
        id="nome" 
        placeholder="NOME" 
        className="w-full p-3 rounded-md shadow-lg" 
        value={name}
        onChange={(e) => setName(e.target.value)}
        required
      />
      <input 
        type="email" 
        name="email" 
        id="email" 
        placeholder="EMAIL" 
        className="w-full p-3 rounded-md shadow-lg"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        required
      />
      <input 
        type="tel" 
        name="telefone" 
        id="telefone" 
        placeholder="TELEFONE" 
        className="w-full p-3 rounded-md shadow-lg"
        value={telephone}
        onChange={handleTelephoneChange}
        required
      />
      <textarea 
        name="mensagem" 
        id="mensagem" 
        placeholder="MENSAGEM" 
        className="w-full h-40 p-2 rounded-md resize-none shadowlg"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        required
      ></textarea>
      <button 
        type="submit" 
        className="bg-gradient-to-t from-[#FF6B00] to-[#FFA41C] transition-all duration-300 hover:scale-110 w-44 p-2 rounded-md cursor-pointer text-base flex items-center justify-center gap-x-1 shadow-lg"
      >
        <img className="h-4" src="https://upcdn.io/12a1z8V/raw/abeventos/images/send.png" alt="" />
        ENVIAR
      </button>
    </form>
  );
}